import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { file, ProjectNote } from '../../models';
import { App } from '../../models/app';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class ProjectNotesDataService extends HttpBaseService {

    private route = environment.api + 'projectNote/app/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    createProjectNote(appId: string, projectId: string, projectNoteName: string, projectNote: any): Observable<ProjectNote> {
        const url = `${this.route}${appId}/projectId/${projectId}/projectNote/${projectNoteName}`;
        return this.httpPost(url, projectNote);
    }

    updateProjectNote(appId: string, projNoteId: string, updatedProjectNote: any): Observable<ProjectNote> {
        const url = `${this.route}${appId}/projNoteId/${projNoteId}`;
        return this.httpPost(url, updatedProjectNote);
    }

    deleteProjectNote(appId: string, projectNoteId: string): Observable<null> {
        const url = `${this.route}${appId}/projectNoteId/${projectNoteId}/delete`;
        return this.httpDelete(url);
    }

    getProjectNotesByProjectId(appId: string, projectId: string): Observable<Array<ProjectNote>> {
        const url = `${this.route}${appId}/projectId/${projectId}`;
        return this.httpGet(url);
    }

    uploadFile(app: App, projNoteId: string, file: FormData): Observable<ProjectNote> {
        const url = `${this.route}${app.id}/projNoteId/${projNoteId}/container/${app['fileContainers'].private.name}/files`;
        return this.httpPost(url, file);
    }

    deleteFile(app: App, projNoteId: string, fileName: string, fileId: string): Observable<ProjectNote> {
        const url = `${this.route}${app.id}/projNoteId/${projNoteId}/fileName/${fileName}/fileId/${fileId}/container/${app['fileContainers'].private.name}/delete`;
        return this.httpDelete(url);
    }

    updateFileDescription(app: App, projNoteId: string, fileId: string, updatedFile: file): Observable<ProjectNote> {
        const url = `${this.route}${app.id}/projNoteId/${projNoteId}/fileId/${fileId}`;
        return this.httpPost(url, updatedFile);
    }
}
