import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { App, Project, Site } from '../../models';
import { SiteDataDataService } from '../data-services';
import { Field } from './models';


@Injectable({
    providedIn: 'root'
})
export class SiteDataLogicService {

    constructor(
        private _siteDataDataService: SiteDataDataService
    ) { }

    updateSiteData(appId: string, projectId: string, siteId: string, data: any): Observable<any> {
        const result$ = new Subject<any>();

        this._siteDataDataService.updateSiteData(appId, projectId, siteId, data)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                },
                error: (error: any) => {
                    result$.error(error);
                }
            });

        return result$.asObservable();
    }

    updateSiteField(app: App, project: Project, site: Site, field: Field, data: any): Observable<any> {
        const result$ = new Subject<any>();

        this._siteDataDataService.updateSiteField(app, project, site, field, data)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                },
                error: (error: any) => {
                    result$.error(error);
                }
            });

        return result$.asObservable();
    }

    updateSiteFields(app: App, project: Project, siteId: string, data: any): Observable<any> {
        const result$ = new Subject<any>();

        this._siteDataDataService.updateSiteFields(app, project, siteId, data)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                },
                error: (error: any) => {
                    result$.error(error);
                }
            });

        return result$.asObservable();
    }

}
