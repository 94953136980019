import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject } from 'rxjs';
import { ProjectNotesServiceModel } from '../models';

import { App } from '../models/app';
import { Project } from '../models/project';
import { file, ProjectNote } from '../models/ProjectNote';
import { AppsService } from './apps.service';
import { ProjectNotesDataService } from './data-services';
import { AppAliasService } from './logic-services';
import { ProjectsService } from './projects.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectNotesService {

    private _app: App;
    private _project: Project;
    private _projectNote: ProjectNote;

    projectNote: BehaviorSubject<ProjectNote> = new BehaviorSubject<ProjectNote>(null);
    projectNotes: BehaviorSubject<ProjectNote[]> = new BehaviorSubject<ProjectNote[]>(null);
    projectNoteLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    RefreshProjectNotes: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    model: ProjectNotesServiceModel = new ProjectNotesServiceModel();

    get projectAlias(): string { return this._appAliasService.projectAlias; }

    constructor(
        private _projectsService: ProjectsService,
        private _appsService: AppsService,
        private _snackBar: MatSnackBar,
        private _projectNotesDataService: ProjectNotesDataService,
        private _appAliasService: AppAliasService,
    ) { }

    loadProjectAndApp(): void {
        this._appsService.app.subscribe((app) => {
            if (app) {
                this._app = app;
            }
        });
        this._projectsService.project.subscribe((project) => {
            if (project) {
                this._project = project;
                if (this._app) {
                    this.getProjectNotesByProjectId(this._app, this._project.id, this.projectAlias);
                }
            }
        });
    }

    createProjectNote(app: App, projectId: string, projectNoteName: string, projectNote: any, projectNoteAlias: string): void {
        let newId = '';
        this._projectNotesDataService.createProjectNote(app.id, projectId, projectNoteName, projectNote)
            .subscribe({
                next: ((projectNote: ProjectNote) => {
                    this.setProjectNote(projectNote);
                    this.addNoteToModel(projectNote);
                    newId = projectNote.id;
                }),
                error: (() => {
                    this._snackBar.open(`Error creating ${projectNoteAlias.toLocaleLowerCase()}`, 'Close', { duration: 2000, });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this._snackBar.open(`${projectNoteAlias} successfully created.`, 'Close', { duration: 2000, });
                this.projectNoteLoading.next(false);
                this.getProjectNotesByProjectId(app, projectId, projectNoteAlias);
                this.getProjectNoteByProjectNoteId(newId);
            });
    }

    updateProjectNote(appId: string, projNoteId: string, updatedProjectNote: ProjectNote, projectNoteAlias: string): void {
        this._projectNotesDataService.updateProjectNote(appId, projNoteId, updatedProjectNote)
            .subscribe({
                next: ((projectNote: ProjectNote) => {
                    this.setProjectNote(projectNote);
                }),
                error: (() => {
                    this._snackBar.open(`Error updating ${projectNoteAlias.toLocaleLowerCase()}`, 'Close', { duration: 2000, });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this._snackBar.open(`${projectNoteAlias} successfully updated.`, 'Close', { duration: 2000, });
                this.projectNoteLoading.next(false);
            });
    }

    deleteProjectNote(app: App, projectNoteId: string, projectId: string, projectNoteAlias: string): void {
        this._projectNotesDataService.deleteProjectNote(app.id, projectNoteId)
            .subscribe({
                next: (() => {
                    this._snackBar.open(`${projectNoteAlias} successfully deleted.`, 'Close', { duration: 2000, });
                }),
                error: (() => {
                    this._snackBar.open(`Error deleting ${projectNoteAlias.toLocaleLowerCase()}`, 'Close', { duration: 2000, });
                }),
                complete: (() => { this.removeNoteFromModel(projectNoteId); }),
            })
            .add(() => {
                this.getProjectNotesByProjectId(app, projectId, projectNoteAlias);
            });
    }

    setProjectNote(projectNote: ProjectNote): void {
        this.projectNote.next(projectNote);
        this._projectNote = projectNote;
    }

    setProjectNotes(projectNotes: ProjectNote[]): void {
        this.projectNotes.next(projectNotes);
    }

    addNoteToModel(projectNote: ProjectNote): void {
        this.model.projectNotes.push(projectNote);
    }

    removeNoteFromModel(deletedProjectNoteId: string): void {
        this.model.projectNotes = this.model.projectNotes.filter((projectNote: ProjectNote) => projectNote.id !== deletedProjectNoteId);
    }

    getProjectNotesByProjectId(app: App, projectId: string, projectNoteAlias: string): void {
        if (!app.showProjectNotes)
            return;

        const existingNotes = this.model.projectNotes.filter((projectNote: ProjectNote) => projectNote.projectId === projectId);

        if (existingNotes.length > 0) {
            this.setProjectNotes(existingNotes);
            return;
        }

        if (!this.model.loading) {
            this.model.loading = true;

            this._projectNotesDataService.getProjectNotesByProjectId(app.id, projectId)
                .subscribe({
                    next: ((projectNotes: ProjectNote[]) => {
                        projectNotes.forEach((projectNote: ProjectNote) => {
                            this.model.projectNotes.push(projectNote);
                        });
                        this.setProjectNotes(projectNotes);
                    }),
                    error: (() => {
                        this.model.projectNotes = new Array<ProjectNote>();
                        this._snackBar.open(`Error getting ${projectNoteAlias.toLowerCase()}s`, 'Close', { duration: 2000, });
                    }),
                    complete: (() => { }),
                })
                .add(() => {
                    this.model.loading = false;
                });
        }
    }

    getProjectNoteByProjectNoteId(projectNoteId: string): ProjectNote {
        const projectNote = this.model.projectNotes.find((projectNote: ProjectNote) => projectNote.id === projectNoteId);

        if (projectNote) {
            this.projectNote.next(projectNote);
            return projectNote;
        }
        else {
            this.projectNote.next(null);
        }
    }

    uploadFile(app: App, projNoteId: string, file: FormData): void {
        this._projectNotesDataService.uploadFile(app, projNoteId, file)
            .subscribe({
                next: ((projectNote: ProjectNote) => {
                    this.setProjectNote(projectNote);
                }),
                error: (() => {
                    this._snackBar.open('Error uploading the file.', 'Close', { duration: 2000, });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this._snackBar.open('File successfully uploaded.', 'Close', { duration: 2000, });
            });
    }

    deleteFile(app: App, projNoteId: string, fileName: string, fileId: string): void {
        this._projectNotesDataService.deleteFile(app, projNoteId, fileName, fileId)
            .subscribe({
                next: ((projectNote: ProjectNote) => {
                    this.setProjectNote(projectNote);
                }),
                error: (() => {
                    this._snackBar.open('Error deleting the file.', 'Close', { duration: 2000, });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this._snackBar.open('File successfully deleted.', 'Close', { duration: 2000, });
            });
    }

    updateFileDescription(app: App, projNoteId: string, fileId: string, updatedFile: file): void {
        this._projectNotesDataService.updateFileDescription(app, projNoteId, fileId, updatedFile)
            .subscribe({
                next: ((projectNote: ProjectNote) => {
                    this.setProjectNote(projectNote);
                }),
                error: (() => {
                    this._snackBar.open('Error updating the file description.', 'Close', { duration: 2000, });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this._snackBar.open('File description successfully updated.', 'Close', { duration: 2000, });
            });
    }
}
