import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class SiteRequestDataService extends HttpBaseService {

    private route = environment.api + 'siteRequest/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    createSiteRequest(appId: string, container: string, siteRequestData: unknown): Observable<any> {
        const url = `${this.route}app/${appId}/container/${container}/siteRequest`;

        return this.httpPost(url, siteRequestData);
    }

    getSiteRequests(appId: string, userId: string): Observable<any> {
        const url = `${this.route}app/${appId}/userId/${userId}/requestedSites`;

        return this.httpGet(url);
    }

    deleteSiteRequest(appId: string, siteId: string, container: string): Observable<any> {
        const url = `${this.route}app/${appId}/site/${siteId}/container/${container}/deleteSiteRequest`;

        return this.httpDelete(url);
    }

    updateSiteRequest(appId: string, siteRequestId: string, container: string, siteRequestData: any): Observable<any> {
        const url = `${this.route}app/${appId}/siteRequestId/${siteRequestId}/container/${container}/updateSiteRequest`;

        return this.httpPost(url, siteRequestData);
    }

}
