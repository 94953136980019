import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject } from 'rxjs';

import { Invoice } from '../models/invoice';
import { InvoiceDataService } from './data-services';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    private _invoices: Invoice[] = [];

    invoices: BehaviorSubject<Invoice[]> = new BehaviorSubject<Invoice[]>(null);
    invoicesLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    site: BehaviorSubject<Invoice> = new BehaviorSubject<Invoice>(null);
    invoiceLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    RefreshSites: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private _snackBar: MatSnackBar,
        private _invoiceData: InvoiceDataService
    ) { }

    setInvoice(invoice: Invoice): void {
        sessionStorage.setItem('invoice', JSON.stringify(invoice));
        this.site.next(invoice);
    }

    getInvoice(): void {
        let _site = JSON.parse(sessionStorage.getItem('invoice'));
        if (_site) {
            this.setInvoice(_site);
        }
    }

    setInvoices(invoices: Invoice[]): void {
        sessionStorage.setItem('invoices', JSON.stringify(invoices));
        this.invoices.next(invoices);
    }

    resetInvoices(): void {
        this.invoices.next(this._invoices);
    }

    disconnectInvoices(): void {
        sessionStorage.removeItem('invoices');
        this.invoices.next(null);
    }

    getInvoicesByAppId(appId: string, year: string, month: string): void {
        this.invoicesLoading.next(true);

        this._invoiceData.getInvoicesByAppId(appId, year, month)
            .subscribe({
                next: (response: any) => {
                    this._invoices = response;
                    this.setInvoices(response);
                },
                error: () => {
                    this._invoices = [];
                    this.disconnectInvoices();
                    this._snackBar.open('Error getting invoices', '', {
                        duration: 2000,
                    });
                }
            }).add(() => {
                this.invoicesLoading.next(false);
            });
    }
}
