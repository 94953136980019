import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { AppsDataService } from './data-services';

@Injectable({
    providedIn: 'root'
})
export class RefreshTokenService {

    constructor(
        private _appsDataService: AppsDataService,
    ) { }

    getRefreshToken(): Observable<any> {
        const getRefreshToken = new Subject<any>();

        this._appsDataService.getRefreshToken()
            .subscribe({
                next: (result) => {
                    getRefreshToken.next(result);
                }
            });

        return getRefreshToken.asObservable();
    }

}
