import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class SiteDataImportDataService extends HttpBaseService {

    private route = environment.api + 'sitedataimport/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    importData(appId: string, projId: string, dataImport: any): Observable<any> {
        const url = `${this.route}app/${appId}/project/${projId}`;

        return this.httpPost(url, dataImport);
    }
}
