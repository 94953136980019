import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { App } from '../models';
import { Notification } from '../models/notification';
import { NotificationDataService } from './data-services';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private _notifications: Notification[] = [];
    notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>(null);
    notification: BehaviorSubject<Notification> = new BehaviorSubject<Notification>(null);
    newNotification: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    notificationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    currentNotification: Notification;
    currentAppNotification: Notification;

    constructor(
        private _snackBar: MatSnackBar,
        private _notificationData: NotificationDataService,
    ) {

        if (sessionStorage.getItem("notification")) {
            let notification = JSON.parse(sessionStorage.getItem("notification"));
            this.setNotification(notification);
        }
    }

    setNotification(notification: Notification): void {
        this.setCurrentNotification(notification);
        sessionStorage.setItem("notification", JSON.stringify(notification));
    }

    private setCurrentNotification(notification: Notification): void {
        this.notification.next(notification);
        this.currentNotification = notification;
    }

    resetNotifications(): void {
        this.notifications.next(this._notifications);
    }

    setNotifications(notifications: any): void {
        sessionStorage.setItem('notifications', JSON.stringify(notifications));
        this.notifications.next(notifications);
    }

    updateNotification(appId: string, projId: string, notification: Notification): Observable<Notification> {
        const result$ = new Subject<any>();

        this._notificationData.updateNotification(appId, projId, notification)
            .subscribe({
                next: (response: Notification) => {
                    this.updateNotificationLocally(response);
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

    removeNotificationFromProject(notificationId: string, projId: string, appId: string): Observable<any> {
        let notification = { notificationId: notificationId, projId: projId, appId: appId };

        const result$ = new Subject<any>();

        this._notificationData.removeNotificationFromProject(notification)
            .subscribe({
                next: (response: unknown) => {
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

    getNotificationsByProjId(appId: string, projId: string): void {
        this.notificationLoading.next(true);

        this._notificationData.getNotificationsByProjId(appId, projId)
            .subscribe({
                next: (response: any) => {
                    this._notifications = response;
                    this.setNotifications(response);
                },
                error: () => {
                    this._notifications = [];
                    this.disconnectNotification();
                    this._snackBar.open('Error getting notifications', '', {
                        duration: 2000,
                    });
                }
            })
            .add(() => {
                this.notificationLoading.next(false);
            });
    }

    getNotificationsByAppId(appId: string): void {
        this.notificationLoading.next(true);

        this._notificationData.getNotificationsByAppId(appId)
            .subscribe({
                next: (response: any) => {
                    this._notifications = response;
                    this.setNotifications(response);
                },
                error: () => {
                    this._notifications = [];
                    this.disconnectNotification();
                    this._snackBar.open('Error getting notifications', '', {
                        duration: 2000,
                    });
                }
            })
            .add(() => {
                this.notificationLoading.next(false);
            });
    }

    getItems(app: App, dataItem: Notification): Observable<any> {
        const result$ = new Subject<any>();

        this._notificationData.getItems(app, dataItem)
            .subscribe({
                next: (value: any) => {
                    result$.next(value);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

    updateNotificationLocally(notification: Notification): void {
        if (this._notifications.find(u => u.id === notification.id)) {
            let index = this._notifications.findIndex(u => u.id === notification.id);
            this._notifications[index] = notification;
        }
        else {
            this._notifications.push(notification);
        }
        this.resetNotifications();
    }

    disconnectNotification(): void {
        this.setCurrentNotification(null);
        sessionStorage.removeItem("notification");
    }

}
