import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { ReportDefinitionHeader } from '../logic-services/models/report';
import { Report } from '../logic-services/models/report/report';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
/* istanbul ignore file */
export class ReportsDataService extends HttpBaseService {
    route = environment.api + 'reports/app/';

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getReportDefinitionsHeaders(appId: string, projectId: string): Observable<ReportDefinitionHeader[]> {
        const url = `${this.route}${appId}/project/${projectId}/report-definitions-headers`;
        return this.httpGet(url);
    }

    getReportByReportDefinitionId(appId: string, projectId: string, reportDefinitionId: string): Observable<Report> {
        const url = `${this.route}${appId}/project/${projectId}/report-definition/${reportDefinitionId}/report`;
        return this.httpGet(url);
    }
}
