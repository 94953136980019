import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { App, DynamicLogicSet } from '../../models';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class MapLogicDataService extends HttpBaseService {

    private route = environment.api + 'mapLogic/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    updateMapLogicWithFilter(appId: string, mapLogic: DynamicLogicSet, filter: string[]): Observable<any> {
        const url = `${this.route}app/${appId}/filter/${filter}`;
        return this.httpPost(url, mapLogic);
    }

    updateMapLogicWithoutFilter(appId: string, mapLogic: DynamicLogicSet): Observable<any> {
        const url = `${this.route}app/${appId}`;
        return this.httpPost(url, mapLogic);
    }

    getMapLogicByProjId(appId: string, projId: string): Observable<any> {
        const url = `${this.route}app/${appId}/proj/${projId}`;
        return this.httpGet(url);
    }

    getAllRecordsByAppId(appId: string): Observable<any> {
        const url = `${this.route}app/${appId}`;
        return this.httpGet(url);
    }

    deleteItem(app: App, mapLogicId: any): Observable<any> {
        const url = `${this.route}app/${app.id}/record/${mapLogicId}`;
        return this.httpDelete(url);
    }

}
