<div
    *ngIf="open"
    class="kh-viewer-container"
>
    <div class="kh-viewer-content">
        <button
            aria-label="Previous image"
            mat-button
            class="kh-image-button kh-image-chevron-left"
            (click)="decreaseIndex()"
        >
            <mat-icon style="font-size: 80px">chevron_left</mat-icon>
        </button>
        <button
            #closeButton
            aria-label="Cancel Action"
            mat-button
            class="kh-image-button"
            (click)="onCancel()"
            [style.left.px]="buttonLeft"
            [style.top.px]="buttonTop"
        >
            <mat-icon>close</mat-icon>
        </button>
        <img
            #fullScreenImage
            (load)="calculateButtonPosition(); orientationChangeDetection()"
            class="kh-d-photo"
            [src]="files[fileIndex].safeUrl"
        />
        <button
            aria-label="Next image"
            mat-button
            class="kh-image-button kh-image-chevron-right"
            (click)="increaseIndex()"
        >
            <mat-icon style="font-size: 80px">chevron_right</mat-icon>
        </button>
        <p
            #altText
            [style.left.px]="altTextLeft"
            [style.top.px]="altTextTop"
            class="kh-d-photo-alt-text"
        >
            {{ files[fileIndex].name }}
        </p>
    </div>
</div>
