/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { Role } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class RolesDataService extends HttpBaseService {

    private route = environment.api + 'roles/';

    constructor(
        private _httpClient: HttpClient
    ) {
        super(_httpClient);
    }

    deleteRole(appId: string, role: Role): Observable<any> {
        const url = `${this.route}app/${appId}/role/${role.id}`;

        return this.httpDelete(url);
    }

    updateRole(appId: string, role: Role): Observable<any> {
        const url = `${this.route}app/${appId}/role`;

        return this.httpPost(url, role);
    }

    updateRoleWithFilter(appId: string, role: Role, filter: string[]): Observable<any> {
        const url = `${this.route}app/${appId}/role/${filter[0]}`;

        return this.httpPost(url, role);
    }

    getAllRoles(appId: string): Observable<any> {
        const url = `${this.route}app/${appId}`;

        return this.httpGet(url);
    }

}
