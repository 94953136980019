<div class="mat-typography app-frame mat-app-background">
    <div class="kh-main-container">
        <kh-app-toolbar></kh-app-toolbar>

        <div class="kh-router-outlet">
            <router-outlet></router-outlet>
        </div>

        <kh-scroll-to-top-button></kh-scroll-to-top-button>
    </div>
    <app-footer></app-footer>

    <photo-dialog></photo-dialog>

    <kh-timeout-tool></kh-timeout-tool>
</div>
