/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AssociateSitesDataService extends HttpBaseService {

    private route = environment.api + 'sites/associate/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    associateAllSites(appId: string, projId: string): Observable<any> {
        const url = `${this.route}app/${appId}/proj/${projId}`;
        return this.httpPost(url, null);
    }

    associateSite(appId: string, projId: string, siteId: string, associate: boolean): Observable<any> {
        const url = `${this.route}app/${appId}/proj/${projId}/site/${siteId}/${associate.toString()}`;
        return this.httpPost(url, null);
    }

}
