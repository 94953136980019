import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Subject } from 'rxjs';

import { App, DynamicLogicSet, Project } from '../../models';
import { Field } from '../../services/logic-services/models/field';
import { ReportsDataService, ReportsDefinitionDataService } from '../data-services';
import { GridColumnProperties } from './models';
import { Report } from './models/report/report';
import { ReportDefinitionHeader } from './models/report/report-definition-header';
import { ReportFormattingData } from './models/report/report-formatting-data';
import { ReportsServiceModel } from './models/report/reports-service-model';

@Injectable({
    providedIn: 'root',
})
export class ReportsLogicService {

    private reportDefinitionsHeadersChanged = new Subject<boolean>();
    private reportFormattingDataSet = new Subject<boolean>();

    model: ReportsServiceModel;
    reportDefinitionsHeadersChanged$ = this.reportDefinitionsHeadersChanged.asObservable();
    reportFormattingDataSet$ = this.reportFormattingDataSet.asObservable();

    constructor(
        private _reportDataService: ReportsDataService,
        private _reportDefinitionDataService: ReportsDefinitionDataService,
        private _snackBar: MatSnackBar
    ) { }

    initModel(): void {
        if (this.model === undefined) {
            this.model = new ReportsServiceModel();
            this.model.applicableConditionalLogic = new Array<DynamicLogicSet>();
            this.model.reportDefinitionsHeaders = new Array<ReportDefinitionHeader>();
            this.model.reportLoading = false;
            this.model.reportsMap = new Map<string, Report>();
            this.model.reportFormatData = new ReportFormattingData();
            this.model.reportFormatData.columnSettings = new Array<GridColumnProperties>();
            this.model.reportFormatData.hiddenColumns = new Array<string>();
            this.model.reportFormatData.numLockedColumns = 0;
            if (sessionStorage.getItem('reportDefinitionFormatMap') !== null)
                this.model.reportFormatDatasMap = new Map<string, ReportFormattingData>(JSON.parse(sessionStorage.getItem('reportDefinitionFormatMap')));
            else
                this.model.reportFormatDatasMap = new Map<string, ReportFormattingData>();
        }
    }

    getReportDefinitionsHeaders(appId: string, projectId: string): void {
        this._reportDataService
            .getReportDefinitionsHeaders(appId, projectId)
            .subscribe({
                next: (result: ReportDefinitionHeader[]) => {
                    this.model.reportDefinitionsHeaders = result;
                    this.reportDefinitionsHeadersChanged.next(true);
                },
                error: () => {
                    this._snackBar.open('Error getting reports', 'Close', { duration: 2000 });
                },
                complete: () => { },
            })
            .add(() => { });
    }

    getReportByReportDefinitionId(appId: string, projectId: string, reportDefinitionId: string): void {
        if (!this.model.reportsMap.has(reportDefinitionId)) {
            this.model.reportLoading = true;
            this._reportDataService
                .getReportByReportDefinitionId(appId, projectId, reportDefinitionId)
                .subscribe({
                    next: (result: Report) => {
                        this.model.reportsMap.set(result.reportDefinitionId, result);
                        if (result.columnSettings?.length)
                            this.model.reportFormatData.columnSettings = result.columnSettings;
                    },
                    error: () => {
                        this._snackBar.open('Error getting report', 'Close', { duration: 2000 });
                    },
                    complete: () => { },
                })
                .add(() => {
                    this.model.reportLoading = false;
                    this.getCurrentReportFormattingData(reportDefinitionId);
                });
        }
        this.getCurrentReportFormattingData(reportDefinitionId);
    }

    getCurrentReportFormattingData(reportDefinitionId: string): void {
        if (reportDefinitionId === undefined || reportDefinitionId === null) return;
        if (this.model.reportsMap.has(reportDefinitionId) && this.model.reportFormatDatasMap.has(reportDefinitionId)) {
            this.model.reportFormatData = this.model.reportFormatDatasMap.get(reportDefinitionId);
            this.reportFormattingDataSet.next(true);
            return;
        }
        if (!this.model.reportFormatDatasMap.has(reportDefinitionId) && this.model.reportFormatData.columnSettings.length) {
            this.model.reportFormatDatasMap.set(reportDefinitionId, this.model.reportFormatData);
            sessionStorage.setItem('reportDefinitionFormatMap', JSON.stringify(Array.from(this.model.reportFormatDatasMap.entries())));
        }
    }

    updateReportFormattingDataInSession(reportDefinitionHeader: ReportDefinitionHeader): void {
        if (this.model.reportFormatData.columnSettings.length || this.model.reportFormatData.filters.length || this.model.reportFormatData.hiddenColumns.length || this.model.reportFormatData.numLockedColumns > 1) {
            this.model.reportFormatDatasMap.set(reportDefinitionHeader.id, this.model.reportFormatData);
            sessionStorage.setItem('reportDefinitionFormatMap', JSON.stringify(Array.from(this.model.reportFormatDatasMap.entries())));
        }
    }

    clearSavedReportData(): void {
        if (this.model.report?.reportDefinitionId) {
            this.model.reportFormatDatasMap.set(this.model.report.reportDefinitionId, this.model.reportFormatData);
        }
        this.model.reportFormatData = new ReportFormattingData();
    }

    clearReportsModel(): void {
        this.clearSavedReportData();
        this.model.reportDefinitionsHeaders = new Array<ReportDefinitionHeader>();
    }

    clearReportsOnDestroy(): void {
        if (this.model !== undefined) {
            this.model.reportDefinitionsHeaders = new Array<ReportDefinitionHeader>();
            this.model.reportsMap = new Map<string, Report>();
        }
    }

    setApplicableConditionalLogicSetIds(project: Project, report: Report): void {
        report.selectedFields.forEach((selectedField: Field) => {
            if (selectedField.conditionalLogicIds?.length > 0) {
                this.model.applicableConditionalLogic = selectedField.conditionalLogicIds.map((conditionalLogicId: string): DynamicLogicSet => {
                    return project.conditionalLogic.find((logic: DynamicLogicSet) => logic.id === conditionalLogicId);
                });
            }
        });
    }

    isAutoSelectedField(field: Field): boolean {
        const autoSelectedFields = ['SiteNo', 'SiteName', 'SiteStatus'];
        return autoSelectedFields.includes(field.serializedId);
    }

    updateReportColumnWidth(app: App, reportDefinitionHeader: ReportDefinitionHeader, columnSettings: GridColumnProperties): void {
        this._reportDefinitionDataService.updateReportColumnWidth(app, reportDefinitionHeader, columnSettings)
            .subscribe({
                next: (result: Report) => {
                    this.model.reportsMap.set(result.reportDefinitionId, result);
                },
                error: () => {
                    this._snackBar.open('Error updating report', 'Close', { duration: 2000 });
                },
                complete: () => { },
            })
            .add(() => {
                this.model.reportLoading = false;
                this.getCurrentReportFormattingData(reportDefinitionHeader.id);
            });
    }

    applyExistingColumnSettings(columnId: string, fieldDefaulwidth: number, columnSettings: GridColumnProperties[]): number {
        let retrievedWidth: number = null;

        columnSettings.forEach((_, index: number) => {
            if (columnSettings[index].field == columnId) {

                if (columnSettings[index].field) {
                    retrievedWidth = columnSettings[index].width;
                    return;
                }
                else {
                    columnSettings[index] = { field: columnId, width: fieldDefaulwidth };
                }
            }
        });

        if (retrievedWidth) {
            return retrievedWidth;
        }

        columnSettings[columnSettings.length] = { field: columnId, width: fieldDefaulwidth };
        return fieldDefaulwidth;
    }
}
