import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { Site } from '../../models';
import { AssociateSitesDataService } from '../data-services';
import { ErrorHandlerService } from '../tools/error-handler.service';

@Injectable({
    providedIn: 'root'
})
export class AssociateSitesLogicService {
    siteAssociated$: Subject<boolean> = new Subject<boolean>();
    sitesAssociated$: Subject<boolean> = new Subject<boolean>();

    associatedSite: Site = new Site();

    constructor(
        private _errorHandlerService: ErrorHandlerService,
        private _associateSitesDataService: AssociateSitesDataService
    ) { }

    associateAllSites(appId: string, projId: string, sitesAlias: string): void {
        this._associateSitesDataService.associateAllSites(appId, projId).subscribe({
            next: () => {
                this.sitesAssociated$.next(true);
            },
            error: (error: Error) => {
                this._errorHandlerService.showMessage(error, `There was an error associating all the ${sitesAlias.toLocaleLowerCase()} to the project.`);
            }
        });
    }

    associateSite(appId: string, projId: string, siteId: string, associate: boolean): void {
        this._associateSitesDataService.associateSite(appId, projId, siteId, associate)
            .subscribe({
                next: (updatedSite: Site) => {
                    this.setAssociatedSite(updatedSite);
                    this.siteAssociated$.next(true);
                },
                error: (error: Error) => {
                    this._errorHandlerService.showMessage(error, `There was an error associating or de-associating the record`);
                }
            });
    }

    setAssociatedSite(site: Site): void {
        this.associatedSite = site;
    }
}
