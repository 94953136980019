import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { SiteDataImportDataService } from './data-services';

@Injectable({
    providedIn: 'root'
})
export class SiteDataImportService {

    constructor(
        private _siteDataImportData: SiteDataImportDataService
    ) { }

    importData(appId: string, projId: string, dataImport: any): Observable<any> {
        const result$ = new Subject<any>();

        this._siteDataImportData.importData(appId, projId, dataImport)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

}
