import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { App } from '../../models';
import { Notification } from '../../models/notification';
import { DifferencesObject, NotificationObject, RequestInfo } from '../logic-services/models';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class NotificationDataService extends HttpBaseService {

    route = environment.api + 'notifications/';

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getListOfActiveNotificationFieldIds(requestInfo: RequestInfo): Observable<string[]> {
        const url = `${this.route}app/${requestInfo.appId}/project/${requestInfo.projectId}/active-notification-field-ids`;
        return this.httpPost(url, requestInfo);
    }

    sendFieldToAzureQueue(appId: string, projectId: string, siteId: string, field: string, notification: NotificationObject): Observable<any> {
        const url = `${this.route}app/${appId}/project/${projectId}/site/${siteId}/field/${field}/email-notification`;
        return this.httpPost(url, notification);
    }

    newAzureQueue(differences: DifferencesObject): Observable<any> {
        const url = `${this.route}app/${differences.appId}/project/${differences.projectId}/differences-object`;
        return this.httpPost(url, differences);
    }

    updateNotification(appId: string, projId: string, notification: Notification): Observable<Notification> {
        const url = `${this.route}app/${appId}/project/${projId}`;
        return this.httpPost(url, notification);
    }

    getNotificationsByProjId(appId: string, projId: string): Observable<any> {
        const url = `${this.route}app/${appId}/project/${projId}`;
        return this.httpGet(url);
    }

    getNotificationsByAppId(appId: string): Observable<any> {
        const url = `${this.route}app/${appId}`;
        return this.httpGet(url);
    }

    removeNotificationFromProject(notification: unknown): Observable<any> {
        const url = `${this.route}removeNotificationFromProject`;
        return this.httpPost(url, notification);
    }

    getItems(app: App, dataItem: Notification): Observable<any> {
        const url = `${this.route}app/${app.id}/${dataItem.id}/delete`;
        return this.httpGet(url);
    }

}
