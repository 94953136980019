import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { App, DocTemplate, Project } from '../models';
import { BulkDocTemplate } from '../models/bulk-templates';
import { DocumentTemplateDataService } from './data-services/document-template-data.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentTemplateService {
    private batchTemplatePosted: Subject<boolean> = new Subject<boolean>();
    private batchBulkTemplatesLoaded: Subject<boolean> = new Subject<boolean>();
    private docTemplateModified: Subject<boolean> = new Subject<boolean>();
    private docTemplatesLoaded: Subject<boolean> = new Subject<boolean>();
    private docTemplatePosted: Subject<boolean> = new Subject<boolean>();

    batchTemplatePosted$ = this.batchTemplatePosted.asObservable();
    batchBulkTemplatesLoaded$ = this.batchBulkTemplatesLoaded.asObservable();
    docTemplatesLoaded$ = this.docTemplatesLoaded.asObservable();
    docTemplatePosted$ = this.docTemplatePosted.asObservable();
    docTemplateModified$ = this.docTemplateModified.asObservable();

    documentTemplates: DocTemplate[] = [];

    bulkDocTemplates: BulkDocTemplate[] = [];

    DocTemplates: BehaviorSubject<DocTemplate[]> = new BehaviorSubject<DocTemplate[]>(null);
    DocTemplate: BehaviorSubject<DocTemplate> = new BehaviorSubject<DocTemplate>(null);
    newDocTemplate: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    DocTemplateLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    GeneratingDocTemplate = new Subject<boolean>();

    currentDocTemplate: DocTemplate;
    currentAppDocTemplate: DocTemplate;

    constructor(
        private _snackBar: MatSnackBar,
        private _docTemplateDataService: DocumentTemplateDataService
    ) {
        if (sessionStorage.getItem("DocTemplate")) {
            let DocTemplate = JSON.parse(sessionStorage.getItem("DocTemplate"));
            this.setDocTemplate(DocTemplate);
        }
    }

    setDocTemplate(docTemplate: DocTemplate): void {
        this.setCurrentDocTemplate(docTemplate);
        sessionStorage.setItem("DocTemplate", JSON.stringify(docTemplate));
    }

    private setCurrentDocTemplate(docTemplate: DocTemplate): void {
        this.DocTemplate.next(docTemplate);
        this.currentDocTemplate = docTemplate;
    }

    resetDocTemplates(): void {
        this.DocTemplates.next(this.documentTemplates);
    }

    setDocTemplates(docTemplates: any): void {
        this.documentTemplates = docTemplates;
        sessionStorage.setItem('DocTemplates', JSON.stringify(docTemplates));
        this.DocTemplates.next(docTemplates);
        this.docTemplatesLoaded.next(true);
    }

    createDocTemplate(app: App, docTemplate: DocTemplate): void {
        this._docTemplateDataService.createDocTemplate(app, docTemplate)
            .subscribe({
                next: ((result: DocTemplate) => {
                    this.postDocTemplateFile(app.id, result.id, result.filePath, docTemplate.docTemplateFile)
                        .subscribe((fileResponse) => {
                            if (fileResponse) {
                                this.updateDocTemplateLocally(fileResponse);
                                this.getDocTemplatesByProjId(app, docTemplate.projectId);

                                this._snackBar.open(`Document Template saved`, 'Close', { duration: 5000 });
                            }
                            else {
                                this._snackBar.open(`Error creating Document Template`, `Close`, { duration: 2000 });
                            }
                        },
                            () => {
                                this._snackBar.open(`Error creating Document Template`, `Close`, { duration: 2000 });
                            }
                        );
                }),
                error: (() => {
                    this._snackBar.open(`Error creating Document Template`, `Close`, { duration: 2000 });
                })
            })
            .add(() => {
                this.docTemplateModified.next(true);

            });
    }

    updateDocTemplate(app: App, docTemplate: DocTemplate): void {
        this._docTemplateDataService.updateDocTemplate(app, docTemplate)
            .subscribe({
                next: ((result: DocTemplate) => {
                    if (docTemplate.docTemplateFile) {
                        this.postDocTemplateFile(app.id, result.id, result.filePath, docTemplate.docTemplateFile).subscribe((fileResponse) => {
                            if (fileResponse) {
                                result.fileName = fileResponse.fileName;
                                result.filePath = fileResponse.filePath;
                                this.updateDocTemplateLocally(result);
                                this.getDocTemplatesByProjId(app, docTemplate.projectId);
                            }
                        },
                            () => {
                                this._snackBar.open(`Error uploading Document Template file`, `Close`, { duration: 2000 });
                            }
                        );
                    }
                    this.updateDocTemplateLocally(result);
                    this.getDocTemplatesByProjId(app, docTemplate.projectId);
                    this._snackBar.open(`Document Template saved`, 'Close', { duration: 5000 });
                }),
                error: (() => {
                    this._snackBar.open(`Error creating Document Template`, `Close`, { duration: 2000 });
                })
            })
            .add(() => {
                this.docTemplateModified.next(true);

            });
    }

    deleteDocTemplate(app: App, docTemplate: DocTemplate): void {
        this._docTemplateDataService.deleteDocTemplate(app, docTemplate).subscribe({
            next: (() => {
                this.getDocTemplatesByProjId(app, docTemplate.projectId);
                this._snackBar.open(`Document Template deleted.`, 'Close', { duration: 5000 });
            }),
            error: (() => {
                this._snackBar.open(`Unable to delete document template`, `Close`, { duration: 2000 });
            })
        }).add(() => {
            this.docTemplateModified.next(true);

        });
    }

    changeActiveDocTemplate(app: App, docTemplate: DocTemplate): void {
        this._docTemplateDataService.changeActiveDocTemplate(app, docTemplate)
            .subscribe({
                next: (() => {
                    this._snackBar.open(`The Document Template '${docTemplate.docTemplateName}' is now '${docTemplate.active ? 'active' : 'de-activated'} `, 'Close', { duration: 5000 });
                }),
                error: (() => {
                    docTemplate.active = !event;
                    this._snackBar.open(`There was an error updating the record`, `Close`, { duration: 2000 });
                })
            });
    }

    postDocTemplateFile(appId: string, recordId: string, filePath: string, docTemplateFile: any): Observable<DocTemplate> {
        let isNew = false;
        let formData = new FormData();
        formData.append("file", docTemplateFile.rawFile);

        if (!filePath) {
            isNew = true;
        }

        const result$ = new Subject<DocTemplate>();

        this._docTemplateDataService.postDocTemplateFile(appId, recordId, isNew, formData)
            .subscribe({
                next: (response: DocTemplate) => {
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

    postBulkTemplate(appId: string, bulkTemplate: BulkDocTemplate): void {
        this._docTemplateDataService.postBulkTemplate(appId, bulkTemplate)
            .subscribe({
                next: ((result: BulkDocTemplate) => {
                    this.bulkDocTemplates.push(result);
                    this._snackBar.open(`Document Template Batch Created `, `Close`, { duration: 2000 });
                }),
                error: (() => {
                    this._snackBar.open(`Error creating Document Template Batch`, `Close`, { duration: 2000 });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this.batchTemplatePosted.next(true);
            });
    }

    removeDocTemplateFromProject(docTemplateId: string, projId: string, appId: string): Observable<any> {
        const docTemplate = { DocTemplateId: docTemplateId, projId: projId, appId: appId };

        const result$ = new Subject<any>();

        this._docTemplateDataService.removeDocTemplateFromProject(docTemplate)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

    getBatchDocumentTemplatesByProjectId(app: App, project: Project): void {
        if (!app.showDocumentTemplate)
            return;

        this._docTemplateDataService.getBatchDocumentTemplateByProjectId(app, project)
            .subscribe({
                next: ((result: BulkDocTemplate[]) => {
                    result.forEach((documentTemplate: BulkDocTemplate) => {
                        if (!this.bulkDocTemplates.some((batchDocumentTemplate: BulkDocTemplate) => batchDocumentTemplate.id === documentTemplate.id))
                            this.bulkDocTemplates.push(documentTemplate);
                    });
                }),
                error: (() => {
                    this._snackBar.open(`Error getting Batch Document Templates`, `Close`, { duration: 2000 });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this.batchBulkTemplatesLoaded.next(true);
            });
    }

    processedStatus(app: App, project: Project): void {
        this._docTemplateDataService.processedStatus(app, project)
            .subscribe({
                next: ((processedBatches: BulkDocTemplate[]) => {
                    processedBatches.forEach((processedBulkTemplate: BulkDocTemplate) => {
                        const batchInModel = this.bulkDocTemplates.some((batchDocumentTemplate: BulkDocTemplate) => batchDocumentTemplate.id === processedBulkTemplate.id);
                        if (batchInModel) {
                            this.bulkDocTemplates = this.bulkDocTemplates.map((bulkDoc: BulkDocTemplate) => {
                                if (bulkDoc.id === processedBulkTemplate.id)
                                    bulkDoc = processedBulkTemplate;
                                return bulkDoc;
                            });

                        }
                    });
                }),
                error: (() => {
                    this._snackBar.open(`Error getting Batch Document Templates Status`, `Close`, { duration: 2000 });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this.batchBulkTemplatesLoaded.next(true);
            });

    }

    getDocTemplatesByProjId(app: App, projId: string): void {
        if (!app.showDocumentTemplate)
            return;

        this.DocTemplateLoading.next(true);

        this._docTemplateDataService.getDocumentTemplates(app.id, projId)
            .subscribe({
                next: ((result: DocTemplate[]) => {
                    this.setDocTemplates(result);
                }),
                error: (() => {
                    this.documentTemplates = [];
                    this.disconnectDocTemplate();
                    this._snackBar.open(`Error getting DocTemplates`, `Close`, { duration: 2000 });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this.DocTemplateLoading.next(false);
            });
    }

    generateDocumentFromTemplate(appId: string, projectId: string, siteId: string, docTemplateId: string, fileName: string): void {
        this.GeneratingDocTemplate.next(true);

        this._docTemplateDataService.generateDocumentFromTemplate(appId, projectId, siteId, docTemplateId)
            .subscribe({
                next: (response: Blob) => {
                    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

                    const a = document.createElement('a');
                    a.download = fileName;
                    a.href = window.URL.createObjectURL(blob);
                    a.click();
                },
                error: () => {
                    this._snackBar.open('Error getting document', '', {
                        duration: 2000,
                    });
                }
            })
            .add(() => {
                this.GeneratingDocTemplate.next(false);
            });
    }

    updateDocTemplateLocally(docTemplate: DocTemplate): void {
        let index;
        if ((index = this.documentTemplates.findIndex(u => u.id === docTemplate.id)) !== -1) {
            this.documentTemplates[index] = docTemplate;
        }
        else {
            this.documentTemplates.push(docTemplate);
        }
        this.resetDocTemplates();
    }

    disconnectDocTemplate(): void {
        this.setCurrentDocTemplate(null);
        sessionStorage.removeItem("DocTemplate");
    }
}
