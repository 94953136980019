import { App, Project, Role } from '.';

/* istanbul ignore file */
export class User {
    acceptedTOS?: boolean;
    active?: boolean = false;
    address?: string;
    appId?: string;
    apps?: App[];
    avatar?: string;
    cellPhone?: string;
    createdBy?: string;
    dateCreated?: string;
    dateRequested?: string = null;
    email?: string;
    firstName?: string;
    id: string;
    isAdmin?: boolean = false;
    isAppAdmin?: boolean = false;
    isApproved?: boolean = false;
    isCellPhoneInternational?: boolean;
    isLoading?: boolean = false;
    isPlatformAdmin?: boolean = false;
    isPrimary?: boolean;
    isProjectAdmin?: boolean = false;
    isProjectUser?: boolean = false;
    isSaving?: boolean = false;
    lastName?: string;
    name?: string;
    office?: string;
    organization?: string;
    projects?: Project[];
    sites?: string[];
    projId?: string;
    region?: string;
    role?: Role;
    roleName?: string;
    roles?: string[];
    setAppAdminIsLoading?: boolean;
    setProjectAdminIsLoading?: boolean;
    setProjectUserIsLoading?: boolean;
    status?: boolean;
    title?: string;
    token?: string;
    type?: string;
    userNo?: string;
    workPhone?: string;
}
