import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { App } from 'src/app/models/app';
import * as XLSX from 'xlsx';
import { Site } from '../../models';
import { BulkSiteImportResultData } from '../../models/bulkSiteImportResultData';
import { SitesImportDataService } from '../data-services/sites-import-data.service';
import { SitesService } from '../sites.service';

@Injectable({
    providedIn: 'root'
})
export class ExcelBulkSiteUploadLogicService {

    private startTimer = new Subject<boolean>();

    startTimer$ = this.startTimer.asObservable();

    intervalId: NodeJS.Timeout;

    constructor(private _sitesImportDataService: SitesImportDataService, private _sitesService: SitesService, private _snackBar: MatSnackBar) { }

    verifyExcelData(columns: string[], siteAliasLowerCase: string, siteAlias: string): boolean {
        const headers = [`Number`, `Name`, `address`, `city`, `state`, `zipCode`, `chargeNumber`, `active`, `show`];
        let result = headers.every(header => columns.includes(header));
        if (!result) {
            this._snackBar.open(`Please make sure the excel file contains the following headers (case sensitive) in the following order: ${siteAliasLowerCase}No,${siteAliasLowerCase}Name,address,city,state,zipCode,chargeNumber,active,show${siteAlias}`, "Close", { duration: 10000 });
            return false;
        }
        return true;
    }

    validateExcelExtension(file: File): boolean {
        if (file.name.indexOf('.xlsx') <= 0) {
            this._snackBar.open('You can only upload excel (.xlsx) files', "Close", { duration: 5000 });
            return false;
        }
        return true;
    }

    startTimerToCheckIsProcessing(app: App, siteAlias: string): void {
        this.intervalId = setInterval(() => {
            this._sitesImportDataService.checkBulkSitesProcessing(app.id)
                .subscribe({
                    next: (result: BulkSiteImportResultData) => {
                        if (!result.bulkSitesProcessing && !result.hasErrors) {
                            clearInterval(this.intervalId);
                            this._sitesService.getSitesByAppId(app, siteAlias);
                            this.startTimer.next(false);
                        }
                    },
                    error: error => {
                        clearInterval(this.intervalId);
                        this._snackBar.open(`${JSON.stringify(error.error)}`, "Close", { duration: 5000 });
                    }
                });
        }, 10000);
    }

    setStartTimer(): void {
        this.startTimer.next(true);
    }

    validateExcelFileForDuplicateUpload(jsonData: any, currentSites: Site[]): Site[] {
        let duplicateSite: Site[] = [];

        jsonData.forEach((site: { Number: string; }) => {
            let duplicates = currentSites.filter((currentSite: Site) => currentSite.siteNo === site.Number);
            if (duplicates.length > 0) {
                duplicateSite.push(duplicates[0]);
            }
        });

        return duplicateSite;
    }

    extractJsonDataFromExcelWorkBook(workBook: XLSX.WorkBook): object {
        let jsonData: object;
        jsonData = workBook.SheetNames.reduce((initial, name) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet, { raw: false, dateNF: "dd/mm/yyyy" });
            return initial;
        }, {});

        return jsonData;
    }

    getExcelColumnsFromWorkBook(workBook: XLSX.WorkBook): string[] {
        const sheet = workBook.Sheets[workBook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as string[][];
        const columns = jsonData[0] as string[];

        return columns;
    }

    importSites(appId: string, projId: string, jsonData: any, sitesAlias: string): void {
        this._sitesImportDataService.importSites(appId, projId, jsonData)
            .subscribe({
                next: () => {
                    this.setStartTimer();
                    this._snackBar.open(`${sitesAlias} Uploaded`, "Close", { duration: 5000 });
                },
                error: error => {
                    this._snackBar.open(`${JSON.stringify(error.error)}`, "Close", { duration: 5000 });
                }
            });
    }
}
