/* eslint-disable max-statements-per-line */
import { ElementRef, Injectable, QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileInfo, SelectEvent } from '@progress/kendo-angular-upload';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Coordinate, GeoJsonFeature, Point, Polygon, Position, Site } from '../../models';
import { GeoJsonFile } from '../../models/geo-json-file';
import { GeoJsonFeatureType, SiteTypeNames, SiteTypes } from '../../shared/enums';
import { AppAliasService } from './app-alias.service';
import { AddressComponentValues, SearchType, SiteFormInputControl, SiteFormLogicServiceModel, SiteFormValues, SuggestedAddress } from './models';

@Injectable({
    providedIn: 'root'
})
export class SiteFormLogicService {

    model: SiteFormLogicServiceModel;
    jsonFileType = 'application/json';
    geoJsonFileType = '.geojson';

    fileLoadedSuccessfully: Subject<boolean> = new Subject<boolean>();
    fileLoadedSuccessfully$ = this.fileLoadedSuccessfully.asObservable();

    detailsLastControlIndex = 10;

    get siteAlias(): string { return this._appAliasService.siteAlias; }

    constructor(private _appAliasService: AppAliasService) {
        if (this.model === undefined) this.model = new SiteFormLogicServiceModel(_appAliasService);
        this.prepareFormValueChanges();
    }

    initModel(siteFormLogicServiceModel: SiteFormLogicServiceModel): void {
        this.model = siteFormLogicServiceModel;
    }

    resetModel(siteFormLogicServiceModel: SiteFormLogicServiceModel): void {
        this.model = siteFormLogicServiceModel;
        this.prepareFormValueChanges();
    }

    prepareFormValueChanges(): void {
        this.mapFormValuesToSummary();
        this.capitalizeFormControlValue('city', this.capitalizeEachWord);
        this.capitalizeFormControlValue('state', this.capitalizeStateControlValue);
        this.capitalizeFormControlValue('address', this.capitalizeEachWord);
    }

    capitalizeFormControlValue(formControlName: string, callback: (val: string) => string): void {
        this.model.siteForm.get(formControlName).valueChanges.subscribe(value => {
            if (value) {
                const formattedValue: string = callback(value);
                this.model.siteForm.get(formControlName).setValue(formattedValue, { emitEvent: false });
            }
        });
    }

    capitalizeEachWord = (word: string | null | undefined): string => {
        if (!word) return "";
        const words: string[] = word.split(' ');

        for (let i = 0; i < words.length; i++) {
            if (this.isNumber(words[i])) continue;
            words[i] = this.capitalizeFirstLetter(words[i]);
        }

        return words.join(' ');
    };

    capitalizeStateControlValue = (state: string | null | undefined): string => {
        if (!state) return "";
        if (state.length > 3) {
            return this.capitalizeEachWord(state);
        }
        return state.toUpperCase();
    };

    capitalizeFirstLetter(word: string): string {
        if (!word) return '';
        const firstLetter: string = word.charAt(0).toUpperCase();
        const restOfString: string = word.slice(1).toLowerCase();

        return firstLetter + restOfString;
    }

    isNumber(value: string): boolean {
        if (!value === undefined || value === null) return false;
        return !isNaN(parseInt(value));
    }

    mapFormValuesToSummary(): void {
        this.model.siteForm.valueChanges.pipe(map(values => { this.processFormValues(values); })).subscribe();
    }

    processFormValues(values: SiteFormValues): void {
        this.model.locationSummary.clear();
        this.model.detailsSummary.clear();

        for (const key in values) {
            if (!this.model.isNewSite && key === "active")
                continue;
            let value = (values[key] === null || values[key] === undefined) ? 'N/A' : values[key];

            if (key === 'siteType') {
                value = (value === SiteTypes.Point) ? SiteTypeNames.Point : SiteTypeNames.LineArea;
                this.model.locationSummary.set(key, value);
                continue;
            }

            if (['address', 'city', 'state', 'zipCode', 'latitude', 'longitude'].includes(key)) {
                this.model.locationSummary.set(key, value);
                continue;
            }
            this.model.detailsSummary.set(key, value);
        }
    }

    populateSummariesWithNA(): void {
        const keys = Object.keys(this.model.siteForm.controls);
        for (const key of keys) {
            const naValue = 'N/A';

            if (['siteType', 'address', 'city', 'state', 'zipCode', 'latitude', 'longitude'].includes(key)) {
                this.model.locationSummary.set(key, naValue);
                continue;
            }

            this.model.detailsSummary.set(key, naValue);
        }
    }

    determineControlsBasedOnWizardStep(): string[] {

        return this.model.currentWizardStep === 0 ? this.getControlsInRange(0, 6) : this.getControlsInRange(7, this.detailsLastControlIndex);
    }

    determineControls(): string[] {
        switch (this.model.currentWizardStep) {
            case 0:
                return (this.model.searchType === SearchType.ADDRESS) ? this.getControlsInRange(0, 4) : this.getLatLongControls();
            default:
                return this.getControlsInRange(7, this.detailsLastControlIndex);
        }
    }

    getControlsInRange(start: number, end: number): string[] {
        const controlNames: string[] = Object.keys(this.model.siteForm.controls);
        return controlNames.slice(start, end + 1);
    }

    getLatLongControls(): string[] {
        const controlNames: string[] = Object.keys(this.model.siteForm.controls);
        return [controlNames[0]].concat(controlNames.slice(5, 7));
    }

    hasErrorInFormControls(): void {
        this.setWizardStepErrorMap(false);

        if (this.model.currentWizardStep === 0 && this.model.siteForm.get('siteType').value === SiteTypes.LineArea) {
            if (!this.model.geoJsonFileName) {
                this.setWizardStepErrorMap(true);
                return;
            }
            return;
        }

        const controls: string[] = this.determineControls();
        for (const key of controls) {
            const control = this.model.siteForm.get(key);

            if (control.status === "INVALID" || control.errors != null) {
                this.setWizardStepErrorMap(true);
                return;
            }
            const controlsWhosValueMayBeFalse = ['active'];

            if (key === 'chargeNumber' && this.model.isAppEnterprise) {
                this.setWizardStepErrorMap(false);
                return;
            }

            if (!control.value && !controlsWhosValueMayBeFalse.includes(key)) {
                this.setWizardStepErrorMap(true);
                return;
            }
        }
    }

    setWizardStepErrorMap(value: boolean): void {
        this.model.wizardStepErrorMap.set(this.model.currentWizardStep, value);
    }

    setAllWizardStepsInErrorMapToFalse(): void {
        for (const key of this.model.wizardStepErrorMap.keys()) {
            this.model.wizardStepErrorMap.set(key, false);
        }
    }

    resetForm(): void {
        this.model.siteForm.reset();
    }

    setLocationSearchType(newSearchType: SearchType): void {
        this.model.searchType = newSearchType;
        this.hasErrorInFormControls();
    }

    setIsNewSite(value: boolean): void {
        this.model.isNewSite = value;
    }

    setUpdatedSite(site: Site): void {
        if (site === undefined) throw new Error(`${this.siteAlias} cannot be undefined`);
        this.model.updatedSite = { ...site };
    }

    setWizardStep(step: number): void {
        if (step < 0 || step > 2) throw new Error('Invalid step');
        this.model.currentWizardStep = step;
    }

    preFillSiteForm(site: Site): void {
        if (site === undefined) throw new Error(`${this.siteAlias} cannot be undefined`);
        this.model.initialSiteNumber = site.siteNo;
        this.model.isNewSite = false;

        this.setUpdatedSite(site);
        for (const key in site) {
            if (!this.model.siteForm.get(key)) continue;
            this.model.siteForm.get(key).setValue(site[key]);
        }

        if (site.geoJson)
            this.preFillSiteFormGeoJsonData(site);

        this.processFormValues(this.model.siteForm.value);
        this.prepareFormValueChanges();
        this.setAllWizardStepsInErrorMapToFalse();

        const searchType: SearchType = this.model.siteForm.get('address')?.value ? SearchType.ADDRESS : SearchType.LAT_LONG;
        this.setLocationSearchType(searchType);
        this.model.siteForm.updateValueAndValidity();
    }

    preFillSiteFormGeoJsonData(site: Site): void {
        this.model.geoJson = site.geoJson;
        this.model.geoJsonFileName = site.geoJsonFileName;

        let geoJsonFile: GeoJsonFile = {
            type: "FeatureCollection",
            features: [site.geoJson]
        };

        let fileBlob = new Blob([JSON.stringify(geoJsonFile)], { type: this.jsonFileType });
        let geoJsonFileObject = new File([fileBlob], site.geoJsonFileName, { type: this.geoJsonFileType });

        let fileInfo: FileInfo = {
            name: geoJsonFileObject.name,
            size: geoJsonFileObject.size,
            rawFile: geoJsonFileObject,
            extension: geoJsonFileObject.name.split('.').pop() || '',
            uid: '1'
        };

        this.model.geoJsonFiles = [fileInfo];
    }

    saveSiteDetails(): void {
        const form: FormGroup = this.model.siteForm;

        let controls: string[] = this.determineControlsBasedOnWizardStep();
        controls.forEach((key: string) => {
            const value = (key === "latitude" || key === "longitude") ? parseFloat(form.get(key).value) : form.get(key).value;
            this.updateSiteDetails(key, value);
        });

        if (this.model.currentWizardStep !== 0)
            return;

        if (this.model.siteForm.get('siteType').value === SiteTypes.LineArea) {
            this.updateSiteGeoJsonDetails();
            return;
        }
    }

    updateSiteDetails(key: string, value: boolean | number | string | null | undefined): void {
        if (this.isValidValue(value)) {
            this.model.updatedSite[key] = value;
        }
    }

    updateSiteGeoJsonDetails(): void {
        this.model.updatedSite.geoJson = this.model.geoJson;
        this.model.updatedSite.geoJsonFileName = this.model.geoJsonFileName;
    }

    isValidValue(value: boolean | number | string | null | undefined): boolean {
        return value !== null && value !== undefined;
    }

    getFullAddress(): string {
        if (this.isFieldValueBlank(this.model.updatedSite.address) && this.isFieldValueBlank(this.model.updatedSite.city) &&
            this.isFieldValueBlank(this.model.updatedSite.state) && this.isFieldValueBlank(this.model.updatedSite.zipCode))
            return '';

        const parts: string[] = [this.model.updatedSite.address, this.model.updatedSite.city, this.model.updatedSite.state, this.model.updatedSite.zipCode];
        return parts.filter(part => part).join(', ');
    }

    updateLatLongInSiteForm(coordinates: google.maps.LatLng): void {
        if (coordinates?.lat) {
            this.model.siteForm.get('latitude').setValue(coordinates.lat());
        }

        if (coordinates?.lng) {
            this.model.siteForm.get('longitude').setValue(coordinates.lng());
        }

        this.handleSiteFormUpdate();
    }

    handleUpdateAddressAndCityStateZipInSiteForm(suggestedAddress: SuggestedAddress): void {
        if (!suggestedAddress) return;

        if (this.model.searchType === SearchType.LAT_LONG) {
            this.updateAddressAndCityStateZipInSiteForm(suggestedAddress);
            return;
        }

        if (this.model.siteForm.get("siteType").value === SiteTypes.LineArea) {
            this.updateCityStateZipInSiteForm(suggestedAddress);
            return;
        }

        if (this.model.changedInputControl === SiteFormInputControl.ADDRESS) {
            if (this.isFormValueBlank('address')) return;
            this.updateCityStateZipInSiteForm(suggestedAddress);
            return;
        }

        if (this.model.changedInputControl === SiteFormInputControl.CITY) {
            if (this.isFormValueBlank('city')) return;
            this.updateStateZipInSiteForm(suggestedAddress);
            return;
        }

        if (this.model.changedInputControl === SiteFormInputControl.ZIP_CODE) {
            if (this.isFormValueBlank('zipCode')) return;
            this.updateCityStateInSiteForm(suggestedAddress);
            return;
        }

        if (this.model.changedInputControl !== SiteFormInputControl.STATE) return;
        if (this.isFormValueBlank('state')) return;
        if (suggestedAddress.country === AddressComponentValues.UNITED_STATES && this.model.siteForm.get('state').value?.length !== 2) {
            this.updateStateZipInSiteForm(suggestedAddress);
            return;
        }

        this.updateZipInSiteForm(suggestedAddress);
    }

    updateAddressAndCityStateZipInSiteForm(suggestedAddress: SuggestedAddress): void {
        this.model.siteForm.get('address').setValue(suggestedAddress.address);
        this.model.siteForm.get('city').setValue(suggestedAddress.city);
        this.model.siteForm.get('state').setValue(suggestedAddress.state);
        this.model.siteForm.get('zipCode').setValue(suggestedAddress.zipCode);
        this.handleSiteFormUpdate();
    }

    updateCityStateZipInSiteForm(suggestedAddress: SuggestedAddress): void {
        this.model.siteForm.get('city').setValue(suggestedAddress.city);
        this.model.siteForm.get('state').setValue(suggestedAddress.state);
        this.model.siteForm.get('zipCode').setValue(suggestedAddress.zipCode);
        this.handleSiteFormUpdate();
    }

    updateCityStateInSiteForm(suggestedAddress: SuggestedAddress): void {
        this.model.siteForm.get('city').setValue(suggestedAddress.city);
        this.model.siteForm.get('state').setValue(suggestedAddress.state);
        this.handleSiteFormUpdate();
    }

    updateStateZipInSiteForm(suggestedAddress: SuggestedAddress): void {
        this.model.siteForm.get('state').setValue(suggestedAddress.state);
        this.model.siteForm.get('zipCode').setValue(suggestedAddress.zipCode);
        this.handleSiteFormUpdate();
    }

    updateCityInSiteForm(suggestedAddress: SuggestedAddress): void {
        this.model.siteForm.get('city').setValue(suggestedAddress.city);
        this.handleSiteFormUpdate();
    }

    updateStateInSiteForm(suggestedAddress: SuggestedAddress): void {
        this.model.siteForm.get('state').setValue(suggestedAddress.state);
        this.handleSiteFormUpdate();
    }

    updateZipInSiteForm(suggestedAddress: SuggestedAddress): void {
        this.model.siteForm.get('zipCode').setValue(suggestedAddress.zipCode);
        this.handleSiteFormUpdate();
    }

    clearAddressAndCityStateZipInSiteForm(): void {
        this.model.siteForm.get('address').setValue('');
        this.model.siteForm.get('city').setValue('');
        this.model.siteForm.get('state').setValue('');
        this.model.siteForm.get('zipCode').setValue('');
        this.handleSiteFormUpdate();
        return;
    }

    handleSiteFormUpdate(): void {
        this.processFormValues(this.model.siteForm.value);
        this.saveSiteDetails();
        this.hasErrorInFormControls();
    }

    clearAddressInput(): void {
        this.model.siteForm.get('address').setValue('');
        this.model.updatedSite.address = null;
    }

    formatSummaryKey(word: string): string {
        if (!word) throw new Error("Word cannot be undefined or null");

        const wordMap: Map<string, string> = this.model.keyWordMap;
        const value = wordMap.get(word);
        return (value) ? value : this.capitalizeFirstLetter(word);
    }

    equalizeFirstWizardStepHeight(wizardSteps: QueryList<ElementRef>): void {
        if (wizardSteps?.length) {
            const firstWizardStepHeight = this.getOffsetHeightFromFirstWizardStep(wizardSteps);
            this.setFirstWizardStepHeight(firstWizardStepHeight);
        }
    }

    getOffsetHeightFromFirstWizardStep(wizardSteps: QueryList<ElementRef>): number {
        const wizardStepArray = wizardSteps.toArray();
        const firstWizardStepElement = wizardStepArray[0].nativeElement as HTMLElement;

        return this.getOffsetHeightFromElement(firstWizardStepElement);
    }

    getOffsetHeightFromElement(element: HTMLElement): number {
        return element.offsetHeight;
    }

    setFirstWizardStepHeight(height: number): void {
        this.model.firstWizardStepHeight = height;
    }

    checkIfSiteNumberExists(sites: Site[], siteNumberToFind: string): void {
        if (this.model.initialSiteNumber === siteNumberToFind) {
            this.setSiteNumberExists(false);
            this.setSiteNumberError(false);
            return;
        }
        const siteNumberExists: boolean = sites.some(site => site.siteNo === siteNumberToFind);
        this.setSiteNumberExists(siteNumberExists);
        this.setSiteNumberError(siteNumberExists);
    }

    setSiteNumberExists(value: boolean): void {
        this.model.siteNumberExists = value;
    }

    setSiteNumberError(siteNumberExists: boolean): void {
        const siteNumberValue = this.model.siteForm.get('siteNo').value;
        if (siteNumberExists || siteNumberValue === null || siteNumberValue === undefined || siteNumberValue === '') {
            this.model.siteForm.get('siteNo').setErrors({
                'siteNumberExists': true
            });
        }
        else {
            this.model.siteForm.get('siteNo').setErrors(null);
        }
    }

    setIsActivateRadioDisabled(value: boolean): void {
        this.model.isActivateRadioDisabled = value;
    }

    setActiveSiteFormValue(value: boolean): void {
        this.model.siteForm.get('active').setValue(value);
    }

    getInvalidChargeNumberText(): string {
        const baseMessage = "The charge number entered is not valid.";
        const additionalMessage = "Enter a valid charge number to activate the site.";

        return this.model.isNewSite ? `${baseMessage} ${additionalMessage}` : baseMessage;
    }

    updatePositionOfGeoJsonPinToClostestPoint(): void {
        if (!this.model.geoJson) return;
        if (this.model.geoJson.geometry.type === GeoJsonFeatureType.Polygon) return;

        if (this.model.geoJson.geometry.type === GeoJsonFeatureType.Point) {
            this.setCenterLatLongForGeoJson(new Coordinate(this.model.geoJson.geometry.coordinates));
            return;
        }

        let centerPointOverride = new Coordinate([this.model.siteForm.get('longitude').value, this.model.siteForm.get('latitude').value]);

        if (this.model.geoJson.geometry.type === GeoJsonFeatureType.MultiPoint) {
            this.movePinToClosestPointOnMultiPoint(this.model.geoJson, centerPointOverride);
            return;
        }

        if (this.model.geoJson.geometry.type === GeoJsonFeatureType.LineString) {
            this.movePinToClosestPointOnLine(this.model.geoJson, centerPointOverride);
            return;
        }

        if (this.model.geoJson.geometry.type === GeoJsonFeatureType.MultiLineString) {
            this.movePinToClosestPointOnClosestLine(this.model.geoJson, centerPointOverride);
            return;
        }

        if (this.model.geoJson.geometry.type === GeoJsonFeatureType.MultiPolygon) {
            this.movePinToClosestPolygon(this.model.geoJson, centerPointOverride);
            return;
        }
    }

    downloadGeoJsonFile(): void {
        if (!this.model.geoJson) return;

        let geoJsonFile = new GeoJsonFile();

        let geoJsonFeature = new GeoJsonFeature(this.model.geoJson.geometry);
        geoJsonFeature.properties = this.model.geoJson.properties;
        geoJsonFile.features = [geoJsonFeature];

        const blob = new Blob([JSON.stringify(geoJsonFile)], { type: this.jsonFileType });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.model.geoJsonFileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    clearGeoJsonFile(): void {
        this.model.siteForm.get('city').setValue('');
        this.model.siteForm.get('state').setValue('');
        this.model.siteForm.get('zipCode').setValue('');
        this.model.siteForm.get('latitude').setValue('');
        this.model.siteForm.get('longitude').setValue('');
        this.model.geoJsonFiles = [];
        this.model.geoJson = undefined;
        this.model.geoJsonFileName = undefined;
        this.handleSiteFormUpdate();
    }

    onGeoJsonFileCleared(): void {
        this.clearGeoJsonFile();
        this.model.geoJsonErrorText = undefined;
        this.hasErrorInFormControls();
    }

    onGeoJsonFileSelected(event: SelectEvent): void {
        const file: File = event.files[0].rawFile;
        this.model.geoJsonErrorText = undefined;
        if (!file) {
            this.clearGeoJsonFile();
            this.fileLoadedSuccessfully.next(false);
            this.model.geoJsonErrorText = 'Invalid GeoJSON file. Please upload a valid file!';
            return;
        }

        if (!file.name.toLocaleLowerCase().endsWith(this.geoJsonFileType) && file.type !== this.jsonFileType) {
            this.clearGeoJsonFile();
            this.fileLoadedSuccessfully.next(false);
            this.model.geoJsonErrorText = 'Invalid GeoJSON file. Only JSON and GeoJSON files allowed!';
            return;
        }

        this.model.geoJsonFileName = file.name;
        const fileReader = new FileReader();
        fileReader.onload = (): void => {
            this.readGeoJsonFile(fileReader);
        };
        fileReader.readAsText(file);
    }

    readGeoJsonFile(fileReader: FileReader): void {
        const text = fileReader.result.toString();
        let shape;

        try {
            shape = JSON.parse(text);
        }
        catch (error) {
            this.clearGeoJsonFile();
            this.fileLoadedSuccessfully.next(false);
            this.model.geoJsonErrorText = 'Error processing GeoJSON file';
            return;
        }

        if (!shape.features) {
            this.clearGeoJsonFile();
            this.fileLoadedSuccessfully.next(false);
            this.model.geoJsonErrorText = 'Invalid GeoJSON file.';
            return;
        }

        if (shape.features?.length === 0) {
            this.clearGeoJsonFile();
            this.fileLoadedSuccessfully.next(false);
            this.model.geoJsonErrorText = 'Invalid GeoJSON file. Please include a shape in the file.';
            return;
        }

        if (shape.features?.length > 1) {
            this.clearGeoJsonFile();
            this.fileLoadedSuccessfully.next(false);
            this.model.geoJsonErrorText = 'GeoJSON file must contain only one shape per site.';
            return;
        }

        this.model.geoJson = shape.features[0];
        this.fileLoadedSuccessfully.next(true);
        this.hasErrorInFormControls();
    }

    calculateAndSetCenterLatLongForGeoJson(): void {
        const geoType = this.model.geoJson.geometry.type;

        if (geoType === GeoJsonFeatureType.Point) {
            let geometry = this.model.geoJson.geometry as Point;
            let coordinate = new Coordinate(geometry.coordinates);
            this.setCenterLatLongForGeoJson(coordinate);
            return;
        }

        if (geoType === GeoJsonFeatureType.Polygon) {
            let centerPoint = this.calculateCenterPointForPolygon(this.model.geoJson);
            this.setCenterLatLongForGeoJson(centerPoint);
            return;
        }

        if (geoType === GeoJsonFeatureType.MultiPoint) {
            let centerPoint = this.calculateCenterPointForLineStringAndMultiPoint(this.model.geoJson);
            this.movePinToClosestPointOnMultiPoint(this.model.geoJson, centerPoint);
            return;
        }

        if (geoType === GeoJsonFeatureType.LineString) {
            let centerPoint = this.calculateCenterPointForLineStringAndMultiPoint(this.model.geoJson);
            this.movePinToClosestPointOnLine(this.model.geoJson, centerPoint);
            return;
        }

        if (geoType === GeoJsonFeatureType.MultiLineString) {
            let centerPoint = this.calculateCenterPointForMultiLineString(this.model.geoJson);
            this.movePinToClosestPointOnClosestLine(this.model.geoJson, centerPoint);
            return;
        }

        if (geoType === GeoJsonFeatureType.MultiPolygon) {
            let centerPoint = this.calculateCenterPointForMultiPolygon(this.model.geoJson);
            this.movePinToClosestPolygon(this.model.geoJson, centerPoint);
            return;
        }
    }

    calculateCenterPointForLineStringAndMultiPoint(geoJson: GeoJsonFeature): Coordinate {
        let latSum = 0;
        let longSum = 0;
        let count = 0;
        geoJson.geometry.coordinates.forEach((coordinate) => {
            latSum += coordinate[1];
            longSum += coordinate[0];
            count++;
        });
        return new Coordinate([longSum / count, latSum / count]);
    }

    calculateCenterPointForMultiLineString(geoJson: GeoJsonFeature): Coordinate {
        let latSum = 0;
        let longSum = 0;
        let count = 0;
        geoJson.geometry.coordinates.forEach((line) => {
            line.forEach((coordinate) => {
                latSum += coordinate[1];
                longSum += coordinate[0];
                count++;
            });
        });
        return new Coordinate([longSum / count, latSum / count]);
    }

    calculateCenterPointForPolygon(geoJson: GeoJsonFeature): Coordinate {
        let latSum = 0;
        let longSum = 0;
        let count = 0;
        geoJson.geometry.coordinates.forEach((coordinate) => {
            for (let i = 0; i < coordinate.length - 1; i++) {
                latSum += coordinate[i][1];
                longSum += coordinate[i][0];
                count++;
            }
        });

        return new Coordinate([longSum / count, latSum / count]);
    }

    calculateCenterPointForMultiPolygon(geoJson: GeoJsonFeature): Coordinate {
        let latSum = 0;
        let longSum = 0;
        let count = 0;
        geoJson.geometry.coordinates.forEach((polygon) => {
            polygon.forEach((coordinate) => {
                for (let i = 0; i < coordinate.length - 1; i++) {
                    latSum += coordinate[i][1];
                    longSum += coordinate[i][0];
                    count++;
                }
            });
        });

        return new Coordinate([longSum / count, latSum / count]);
    }

    movePinToClosestPointOnMultiPoint(feature: GeoJsonFeature, centerPoint: Coordinate): void {
        let closestPoint = new Coordinate([centerPoint.lng, centerPoint.lat]);
        let minDistanceFound = Infinity;

        feature.geometry.coordinates.forEach(point => {
            const pointCoordinate = new Coordinate([point[1], point[0]]);
            const distanceToClostestPoint = this.calculateDistanceBetweenPoints(pointCoordinate, centerPoint);
            minDistanceFound = this.setClosestPointAndReturnMinDistance(minDistanceFound, closestPoint, distanceToClostestPoint, pointCoordinate);
        });

        this.setCenterLatLongForGeoJson(closestPoint);
    }

    movePinToClosestPointOnLine(feature: GeoJsonFeature, centerPoint: Coordinate): void {
        if (feature.geometry.type !== GeoJsonFeatureType.LineString) return;

        let closestPoint = new Coordinate([centerPoint.lng, centerPoint.lat]);
        let minDistanceFound = Infinity;

        for (let i = 0; i < feature.geometry.coordinates.length - 1; i++) {
            let closestPointOnLineSegment = new Coordinate([0, 0]);
            const point1 = new Coordinate(feature.geometry.coordinates[i]);
            const point2 = new Coordinate(feature.geometry.coordinates[i + 1]);

            let closestPointOnLineSegmentDistance = this.findClostestPointOnLineSegmentAndDistance(point1, point2, centerPoint, closestPointOnLineSegment);
            minDistanceFound = this.setClosestPointAndReturnMinDistance(minDistanceFound, closestPoint, closestPointOnLineSegmentDistance, closestPointOnLineSegment);
        }

        this.setCenterLatLongForGeoJson(closestPoint);
    }

    movePinToClosestPointOnClosestLine(feature: GeoJsonFeature, centerPoint: Coordinate): void {
        let closestPoint = new Coordinate([centerPoint.lng, centerPoint.lat]);
        let minDistanceFound = Infinity;

        feature.geometry.coordinates.forEach(line => {
            for (let i = 0; i < line.length - 1; i++) {
                let closestPointOnLineSegment = new Coordinate([0, 0]);
                const point1 = new Coordinate(line[i]);
                const point2 = new Coordinate(line[i + 1]);

                let closestPointOnLineSegmentDistance = this.findClostestPointOnLineSegmentAndDistance(point1, point2, centerPoint, closestPointOnLineSegment);
                minDistanceFound = this.setClosestPointAndReturnMinDistance(minDistanceFound, closestPoint, closestPointOnLineSegmentDistance, closestPointOnLineSegment);
            }
        });

        this.setCenterLatLongForGeoJson(closestPoint);
    }

    movePinToClosestPolygon(geoJson: GeoJsonFeature, centerPoint: Coordinate): void {
        let closestPoint = new Coordinate([centerPoint.lng, centerPoint.lat]);
        let minDistanceFound = Infinity;

        geoJson.geometry.coordinates.forEach((polygon) => {
            polygon = this.makeGeoJsonPolygonFeatureFromCoordinates(polygon);
            let polygonCenterPoint = this.calculateCenterPointForPolygon(polygon);
            const distanceToCurrentPolygon = this.calculateDistanceBetweenPoints(polygonCenterPoint, centerPoint);
            minDistanceFound = this.setClosestPointAndReturnMinDistance(minDistanceFound, closestPoint, distanceToCurrentPolygon, polygonCenterPoint);
        });

        this.setCenterLatLongForGeoJson(closestPoint);
    }

    findClostestPointOnLineSegmentAndDistance(point1: Coordinate, point2: Coordinate, centerPoint: Coordinate, closestSegmentPoint: Coordinate): number {
        const lineSegmentLongitudeDistance = point2.lng - point1.lng;
        const lineSegmentLatitudeDistance = point2.lat - point1.lat;
        const lineSegmentSquareDistance = lineSegmentLongitudeDistance * lineSegmentLongitudeDistance + lineSegmentLatitudeDistance * lineSegmentLatitudeDistance;
        const lambda = ((centerPoint.lng - point1.lng) * lineSegmentLongitudeDistance + (centerPoint.lat - point1.lat) * lineSegmentLatitudeDistance) / lineSegmentSquareDistance;

        if (this.isTheClosestPointBeforeLineSegment(lambda)) {
            closestSegmentPoint.lat = point1.lat;
            closestSegmentPoint.lng = point1.lng;
            return this.calculateDistanceBetweenPoints(centerPoint, closestSegmentPoint);
        }

        if (this.isTheClosestPointAfterLineSegment(lambda)) {
            closestSegmentPoint.lat = point2.lat;
            closestSegmentPoint.lng = point2.lng;
            return this.calculateDistanceBetweenPoints(centerPoint, closestSegmentPoint);
        }

        closestSegmentPoint.lng = point1.lng + lambda * lineSegmentLongitudeDistance;
        closestSegmentPoint.lat = point1.lat + lambda * lineSegmentLatitudeDistance;
        return this.calculateDistanceBetweenPoints(centerPoint, closestSegmentPoint);
    }

    setCenterLatLongForGeoJson(centerPoint: Coordinate): void {
        this.model.siteForm.get('latitude').setValue(centerPoint.lat);
        this.model.siteForm.get('longitude').setValue(centerPoint.lng);
    }

    setClosestPointAndReturnMinDistance(minDistanceFound: number, closestPoint: Coordinate, segmentDistance: number, closestSegmentPoint: Coordinate): number {
        if (segmentDistance >= minDistanceFound) return minDistanceFound;
        closestPoint.lat = closestSegmentPoint.lat;
        closestPoint.lng = closestSegmentPoint.lng;
        return segmentDistance;
    }

    calculateDistanceBetweenPoints(point1: Coordinate, point2: Coordinate): number {
        let radiusOfEarth = 6371e3;
        const lat1Radians = this.convertToRadians(point1.lat);
        const lat2Radians = this.convertToRadians(point2.lat);
        const latDifferenceRadians = this.convertToRadians(point2.lat - point1.lat);
        const longDifferenceRadians = this.convertToRadians(point2.lng - point1.lng);

        //Haversine formula for calculating distance between two points on a sphere
        //a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
        //c = 2 ⋅ atan2( √a, √(1−a) )
        //d = R ⋅ c // where R is the radius of the Earth and d is the distance between the two points
        const a = Math.sin(latDifferenceRadians / 2) * Math.sin(latDifferenceRadians / 2) +
            Math.cos(lat1Radians) * Math.cos(lat2Radians) *
            Math.sin(longDifferenceRadians / 2) * Math.sin(longDifferenceRadians / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return radiusOfEarth * c;
    }

    isTheClosestPointBeforeLineSegment(lambda: number): boolean {
        return lambda < 0;
    }

    isTheClosestPointAfterLineSegment(lambda: number): boolean {
        return lambda > 1;
    }

    convertToRadians(degrees: number): number {
        return degrees * Math.PI / 180;
    }

    setInputChangedControlName(controlName: string): void {
        this.model.changedInputControl = controlName;
    }

    isFormValueBlank(formControlName: string): boolean {
        let value = this.model.siteForm.get(formControlName).value;
        return this.isFieldValueBlank(value);
    }

    isFieldValueBlank(value: number | string | null | undefined): boolean {
        return value === null || value === undefined || value.toString() === '';
    }

    makeGeoJsonPolygonFeatureFromCoordinates(coordinates: Position[][]): GeoJsonFeature {
        let feature = new GeoJsonFeature(new Polygon());
        feature.type = GeoJsonFeatureType.Polygon;
        feature.geometry.type = GeoJsonFeatureType.Polygon;
        feature.geometry.coordinates = coordinates;
        return feature;
    }
}
