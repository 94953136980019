import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, Project, User } from '../../models';
import { UserProjectLists } from '../logic-services/models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class ProjectsDataService extends HttpBaseService {

    private route = environment.api + 'projects/app/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    getAllUserProjects(appId: string): Observable<UserProjectLists> {
        const url = `${this.route}${appId}/projects`;
        return this.httpGet(url);
    }

    getProjectByAppIdAndProjectId(appId: string, projectId: string): Observable<Project> {
        const url = `${this.route}${appId}/project/${projectId}`;
        return this.httpGet(url);
    }

    saveDraft(app: App, project: Project): Observable<Project> {
        const url = `${this.route}${app.id}/layoutDraft`;
        return this.httpPost(url, project);
    }

    publishProject(app: App, project: Project): Observable<Project> {
        const url = `${this.route}${app.id}/JSONForms,layoutDraft`;
        return this.httpPost(url, project);
    }

    updateProject(appId: string, project: Project): Observable<Project> {
        const url = `${this.route}${appId}`;
        return this.httpPost(url, project);
    }

    updateProjectWithFilter(appId: string, project: Project, filter: string[]): Observable<Project> {
        const url = `${this.route}${appId}/${filter}`;
        return this.httpPost(url, project);
    }

    getProjectAdminsByProjectId(appId: string, projectId: string): Observable<User[]> {
        const url = `${this.route}${appId}/project/${projectId}/admins`;
        return this.httpGet(url);
    }

    createItem(appId: string, project: Project): Observable<Project> {
        const url = `${this.route}${appId}`;
        return this.httpPost(url, project);
    }

    exportLayout(appId: string, projectId: string): Observable<any> {
        const url = `${this.route}${appId}/project/${projectId}/layout`;
        return this.httpDownload(url);
    }

    uploadLayout(appId: string, projectId: string, asdf: FormData): Observable<any> {
        const url = `${this.route}${appId}/project/${projectId}/layout`;
        return this.httpPost(url, asdf);
    }

    addProjectAdmin(appId: string, projId: string, userId: string): Observable<any> {
        const url = `${this.route}${appId}/project/${projId}/user/${userId}/true`;
        return this.httpGet(url);
    }

}
