import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { PhotoDialogService } from 'src/app/services/photo-dialog.service';

@Component({
  selector: 'photo-dialog',
  templateUrl: './photo-dialog.component.html',
  styleUrls: ['./photo-dialog.component.scss']
})
export class PhotoDialogComponent implements OnInit {

  constructor(
    private _photoDialogService: PhotoDialogService
  ) { }

  private _openSubscription: Subscription
  private _filesSubscription: Subscription
  private _fileIndexSubscription: Subscription

  public open: boolean;
  public files: any[];
  public fileIndex: any;

  @ViewChild('fullScreenImage') fullScreenImage: ElementRef;
  @ViewChild('closeButton') closeButton: ElementRef;
  @ViewChild('altText') altText: ElementRef;

  buttonLeft: any;
  buttonTop: any;
  altTextLeft: any;
  altTextTop: any;
  lockFunction = window.screen.orientation;


  ngOnInit(): void {
    this._openSubscription = this._photoDialogService.open.subscribe(o => {
      if(o) {
        this.open = o;
      }
    });

    this._filesSubscription = this._photoDialogService.files.subscribe(f => {
      if(f) {
        this.files = f;
      }
    });

    this._fileIndexSubscription = this._photoDialogService.fileIndex.subscribe(i => {
      this.fileIndex = i;
    });
  }

  calculateButtonPosition() {
    this.buttonLeft = (this.fullScreenImage.nativeElement.offsetLeft + this.fullScreenImage.nativeElement.offsetWidth) - this.closeButton['_elementRef'].nativeElement.offsetWidth;
    this.buttonTop = (this.fullScreenImage.nativeElement.offsetTop - (this.fullScreenImage.nativeElement.offsetHeight/2) - this.closeButton['_elementRef'].nativeElement.offsetHeight);
    this.altTextLeft = this.fullScreenImage.nativeElement.offsetLeft;
    this.altTextTop = (this.fullScreenImage.nativeElement.offsetTop + (this.fullScreenImage.nativeElement.offsetHeight/2));
  }

  orientationChangeDetection() {
    const _this = this;
    this.lockFunction.addEventListener('change', (e) => {
        setTimeout(function() {
            _this.calculateButtonPosition();
        }, 100);
    });
  }

  increaseIndex() {
    this.calculateIndex(true);
    this.calculateButtonPosition();
  }

  decreaseIndex() {
    this.calculateIndex(false);
    this.calculateButtonPosition();
  }

  onCancel(){
      window.removeEventListener("orientationchange", (e) => {

      });
      this.open = false;
      this._photoDialogService.disconnect();
  }

  ngOnDestroy() {
    this._openSubscription.unsubscribe();
    this._filesSubscription.unsubscribe();
    this._fileIndexSubscription.unsubscribe();
  }

  calculateIndex(increase) {
    switch(increase) {
        case true:
        if(this.fileIndex === (this.files.length-1)) {
            this.fileIndex = 0;
        } else {
            this.fileIndex++;
        }
        break;
        case false:
        if(this.fileIndex === 0) {
            this.fileIndex = (this.files.length-1);
        } else {
            this.fileIndex--;
        }
        break;
    }
  }

}
