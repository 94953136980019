import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Subject } from 'rxjs';

import { ChargeNumbers } from '../../shared/enums';
import { ProjectNumberDataService } from '../data-services/project-number-data-service.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectNumberLogicService {

    isValidChargeNo = true;
    validChargeNo$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private _projectNumberDataService: ProjectNumberDataService
    ) {
    }

    triggerUpdate(subject: Subject<boolean>): void {
        subject.next(true);
    }

    validateChargeNo(chargeNo: string): void {
        this._projectNumberDataService.validateChargeNo(chargeNo).subscribe({
            next: (isValidChargeNo) => {
                this.setValidChargeNo(isValidChargeNo['exists']);
            },
            error: (error) => {
                throw new Error(error);
            }
        });
    }

    setValidChargeNo(value: boolean): void {
        this.isValidChargeNo = value;
        this.triggerUpdate(this.validChargeNo$);
    }

    chargeCodeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value === "") {
                if (this.isValidChargeNo)
                    this.setValidChargeNo(false);
                return { required: true };
            }
            if (!this.isValidChargeNo) {
                return { invalid: true };
            }
            return null;
        };
    }

    chargeCodeDemoValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value === ChargeNumbers.Demo)
                return { cannotUseDemo: true };
            return null;
        };
    }
}
