import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BulkDocTemplate } from './../../models/bulk-templates';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, DocTemplate, Project } from '../../models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class DocumentTemplateDataService extends HttpBaseService {
    private route = environment.api + 'DocTemplates/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    getDocumentTemplates(appId: string, projectId: string): Observable<DocTemplate[]> {
        const url = `${this.route}app/${appId}/proj/${projectId}`;
        return this.httpGet(url);
    }

    postBulkTemplate(appId: string, bulkTemplate: BulkDocTemplate): Observable<BulkDocTemplate> {
        const url = `${this.route}bulk-template/${appId}`;
        return this.httpPost(url, bulkTemplate);
    }

    getBatchDocumentTemplateByProjectId(app: App, project: Project): Observable<BulkDocTemplate[]> {
        const url = `${this.route}bulk-template/${app.id}/${project.id}`;
        return this.httpGet(url);
    }

    processedStatus(app: App, project: Project): Observable<BulkDocTemplate[]> {
        const url = `${this.route}bulk-template/${app.id}/${project.id}/status`;
        return this.httpGet(url);
    }

    createDocTemplate(app: App, docTemplate: DocTemplate): Observable<DocTemplate> {
        const url = `${this.route}create/${app.id}`;
        return this.httpPost(url, docTemplate);
    }

    updateDocTemplate(app: App, docTemplate: DocTemplate): Observable<DocTemplate> {
        const url = `${this.route}update/${app.id}`;
        return this.httpPut(url, docTemplate);
    }

    changeActiveDocTemplate(app: App, docTemplate: DocTemplate): Observable<DocTemplate> {
        const url = `${this.route}changeActive/${app.id}`;
        return this.httpPut(url, docTemplate);
    }

    deleteDocTemplate(app: App, docTemplate: DocTemplate): Observable<DocTemplate> {
        const url = `${this.route}delete/${app.id}/${docTemplate.id}`;
        return this.httpDelete(url);
    }

    generateDocumentFromTemplate(appId: string, projectId: string, siteId: string, docTemplateId: string): Observable<any> {
        const url = `${this.route}getDocTemplate/${appId}/${projectId}/${siteId}/${docTemplateId}`;
        return this.httpDownload(url);
    }

    postDocTemplateFile(appId: string, recordId: string, isNew: boolean, formData: FormData): Observable<DocTemplate> {
        const url = `${this.route}app/${appId}/file?recordId=${recordId}&isNew=${isNew.toString()}`;
        return this.httpPost(url, formData);
    }

    removeDocTemplateFromProject(docTemplate: unknown): Observable<any> {
        const url = `${this.route}removeDocTemplateFromProject`;
        return this.httpPost(url, docTemplate);
    }

}
